import { Box, Drawer, Stack, Typography, styled, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AppTopBar from './AppTopBar'
import SideDrawer from './SideDrawer'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { APP_BAR_HEIGHT } from './constants';


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Navigation = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const currentLocation = useLocation();

    useEffect(() => {
        if (currentLocation.pathname.length < 2) {
            navigate('/strengthIndicator')
        }
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <AppTopBar open={open} handleDrawerOpen={handleDrawerOpen} />
            <SideDrawer open={open} handleDrawerClose={handleDrawerClose} />
            <Stack direction='column' sx={{ flexGrow: 1, height: '100vh', display: 'flex' }}>
                <DrawerHeader />
                <Outlet />
            </Stack>
        </Box>
    )
}

export default Navigation
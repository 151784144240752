import './App.css';
import Navigation from './components/navigation/Navigation';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FiiDii from './components/fiidii/fiidii';
import SingleDayData from './components/fiidii/singleDayData';
import FutureNetOIvsIndex from './components/fiidii/futureNetOIvsIndex';
import ResultCalendar from './components/resultsCalendar';
import StrengthIndicator from './components/strengthIndicator';
import PutCallRatio from './components/putcallratio';
import { useEffect } from 'react';
import { marketStatusActions } from './store/marketStatus';
import { useDispatch } from 'react-redux';
import { onValue, ref } from 'firebase/database';
import { database } from './config/firebase';
import StockDelivery from './components/stockDelivery';
import { stockListActions } from './store/stocksList';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    onValue(ref(database, "market_status"), (data) => {
      dispatch(marketStatusActions.update(data.val()));
    })
    dispatch(stockListActions.update());
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigation />}>
          <Route path='fiidii' element={<FiiDii />}>
            <Route path='singleDayData' element={<SingleDayData />} />
            <Route path='futureNetOIvsIndex' element={<FutureNetOIvsIndex />} />
          </Route>
          <Route path='resultsCalendar' element={<ResultCalendar />} />
          <Route path='strengthIndicator' element={<StrengthIndicator />} />
          <Route path='pcr' element={<PutCallRatio />} />
          <Route path='stockDelivery' element={<StockDelivery />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

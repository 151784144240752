import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { SocialIcon } from 'react-social-icons'

const Footer = () => {
    return (
        <Paper elevation={2}>
            <Stack direction='row' padding={2} style={{backgroundColor: '#B6BBC4'}}>
                <Stack flexGrow={1} direction='column'>
                    <Typography>
                        MarketDirection
                    </Typography>
                </Stack>
                <Stack direction='column' paddingX={4}>
                    <Typography fontFamily='monospace' fontSize={14}>
                        COMPANY
                    </Typography>
                    <Typography>
                        About
                    </Typography>
                    <Typography>
                        Contact
                    </Typography>
                    <Typography>
                        Blog
                    </Typography>
                </Stack>
                <Stack direction='column' paddingX={4}>
                    <Typography fontFamily='monospace' fontSize={14}>
                        SOCIAL
                    </Typography>
                    <SocialIcon network='youtube' style={{maxHeight: 24, maxWidth: 24, marginTop: 3}} />
                    <SocialIcon network='twitter' style={{maxHeight: 24, maxWidth: 24, marginTop: 3}}/>
                </Stack>
            </Stack>

        </Paper>
    )
}

export default Footer